.task-item {
  padding: 10px;
  border: 1px solid;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: white;
}

.App-link {
  color: #61dafb;
} 

span {
    cursor: pointer;
}

span.status.wrapper-span {
  top: 0;
  right: 40px;
  bottom: auto;
}

span.delete {
  position: absolute;
  top: -2px;
  left: -9px;
  background-color: #3c3c3c;
  font-size: 13px;
  padding: 2px 4px;
  border-radius: 52px;
}

span.value {
  padding: 0 6px;
}

span.status {font-size: 10px;}
span.wrapper-span {
  display: flex;
  align-items: flex-end;
}

.btn-wrapper {
  display: flex;
  position: absolute;
  top: -5px; 
  right: 0pc;
}
form {
  display: flex;
  align-items: flex-end;  
    flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

span.up-btn, span.down-btn {
  font-size: 16px;
  margin: 0 5px;
}
span.value, .due-date{font-size: 10px;}
.due-date{padding-top: 5px;padding-right: 5px;}
span.wrapper-span {
  position: absolute;
  bottom: 0;
  right: 4px;
}

span.title {
  width: 80%;
}